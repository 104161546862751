nav {
  left: 0;
  top: -1px;
  width: 100%;
  height: 0;
  position: fixed;
  z-index: 3;
  display: none;
  display: block;
  overflow: hidden;
  background: rgb(233, 247, 253);
  background: $yellow;
  a {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      display: block;
      margin-top: 5px;
      right: 0;
      background: $black;
      transition: 0.4s ease;
    }
    &:hover {
      &:after {
        width: 100%;
        left: 0;
        color: $black;
      }
    }
  }
  .nav-columns {
    transform: translateY(clamp(90px, 25vw, 125px));
    display: flex;
    padding: 0 32px;
    @include media("<=654px") {
      flex-direction: column;
    }
    @include media("<=phone") {
      padding: 0 16px;
    }
    .nav-column {
      width: 45%;
      @include media("<=654px") {
        width: 100%;
      }
      &:last-child {
        width: 55%;
        @include media("<=654px") {
          width: 100%;
        }
      }
      .nav-label {
        margin-bottom: clamp(0.875rem, 4vw, 3rem);
        font-size: clamp(0.875rem, 3vw, 1.2rem);
        &.contact-nav-label {
          @include media("<=phone") {
            margin-top: 0.5rem;
          }
        }
      }
      .nav-links {
        padding: 0;
        margin: 0;
        li {
          list-style: none;
          margin-bottom: clamp(0.875rem, 3vw, 2.8rem);
          a {
            font-weight: 600;
            text-decoration: none;
            color: $black;
            font-size: clamp(1.7rem, 4vw, 2.8rem);
            line-height: clamp(1.6rem, 3vw, 2.8rem);
          }
        }
      }
      .nav-infos {
        display: flex;
        flex-wrap: wrap;
        @include media("<=654px") {
          justify-content: space-between;
        }
        .nav-info {
          padding: 0;
          width: 50%;
          &:nth-child(1),
          &:nth-child(2) {
            margin-top: 0;
          }
          &:nth-child(4) {
            @include media("<=phone") {
              margin-top: 0;
            }
          }
          &:nth-child(2),
          &:nth-child(3) {
            @include media("<=654px") {
              display: none;
            }
          }
          .nav-info-label {
            font-weight: 600;
          }
          li {
            font-weight: 300;
            list-style: none;
            font-size: 1.4rem;
            margin-bottom: 1.2rem;
            &:nth-of-type(3) {
              @include media("<=phone") {
                display: none;
              }
            }
            @include media("<=tablet") {
              font-size: 1.2rem;
              margin-bottom: 1rem;
            }
            @include media("<=phone") {
              font-size: 1rem;
              margin-bottom: 0.875rem;
            }
            a {
              text-decoration: none;
              color: $black;
            }
          }
        }
      }
    }
  }
}
