.header {
  position: fixed;
  width: 100%;
  height: 128px;
  z-index: 4;
  &.mix-blend {
    mix-blend-mode: difference;
  }
  @include media("<=phone") {
    height: 96px;
  }
  .logo {
    font-size: 1.2rem;
    letter-spacing: 0.5rem;
    color: $white;
    text-decoration: none;
    font-weight: 700;
    a {
      text-decoration: none;
      &:visited {
        color: inherit;
      }
    }
  }
  .nav-toggle {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media("<=tablet") {
      margin-right: 24px;
    }
    @include media("<=phone") {
      margin-right: 16px;
      width: 20px;
    }
    .hamburger-menu {
      width: 25px;
      span {
        margin-bottom: 0.3rem;
        background: $white;
        height: 2px;
        width: 100%;
        display: block;
      }
    }
    .hamburger-menu-close {
      position: absolute;
      display: none;
      fill: $white !important;
      svg {
        stroke: $white !important;
        @include media("<=tablet") {
          width: 56px;
        }
        @include media("<=phone") {
          width: 48px;
        }
      }
    }
  }
}
