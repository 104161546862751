.cases {
  height: 50vh;
  height: calc(var(--vh, 1vh) * 50);
  @include media(">=tablet") {
    overflow: hidden;
  }
  .cases-navigation {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 6;
    padding: 32px 50px;
    box-sizing: border-box;
    pointer-events: none;
    @include media("<=tablet") {
      display: none;
    }
    .cases-arrow {
      background: rgba(0, 0, 0, 0.4);
      height: clamp(60px, 4vw, 80px);
      width: clamp(60px, 4vw, 80px);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      pointer-events: all;
      transition: all 1s;
      svg {
        color: $white;
        height: clamp(30px, 2vw, 40px);
        width: clamp(30px, 2vw, 40px);
      }
      &.disabled {
        opacity: 0.3;
        cursor: initial;
      }
    }
  }
  .row {
    @include media("<=tablet") {
      flex-direction: column;
    }

    .case {
      position: relative;
      background: $black;
      z-index: 1;
      transform: translateX(-853.3px);
      transition: transform 0.2s ease-in-out;
      cursor: pointer;
      &:hover {
        .case-image {
          opacity: 0.4;
        }
      }
      .case-details {
        width: 33.34vw;
        height: 50vh;
        height: calc(var(--vh, 1vh) * 50);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 50px;
        box-sizing: border-box;
        z-index: 3;
        position: relative;
        @include media("<=tablet") {
          width: 100vw;
        }
        @include media("<=phone") {
          padding: 16px;
        }
        span {
          margin-top: 156px;
          font-size: clamp(1.2rem, 2vw, 2rem);
          opacity: 0.8;
          color: $white;
          font-weight: 600;
          @include media("<=desktop", ">tablet") {
            font-size: 1.4rem;
          }
          @include media("<=phone") {
            font-size: 1.2rem;
            line-height: 2.2rem;
          }
        }
        h2 {
          font-size: clamp(1.3rem, 2.5vw, 2.8rem);
          line-height: clamp(1.3rem, 2.5vw, 2.8rem);
          width: 85%;
          margin-top: 16px;
          color: $white;
          @include media("<=phone") {
            margin-top: 8px;
          }
        }
      }
      .case-image {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        opacity: 0.65;
        transition: 0.4s cubic-bezier(0.6, -0.05, 0.1, 0.99);
        z-index: 2;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .case-image-zoom-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
    .case-image-zoom {
      position: absolute;
      top: 50%;
      width: 33.333vw;
      height: 50%;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 99;
      background: $black;
      visibility: hidden;
      img {
        opacity: 0.65;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
