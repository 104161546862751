.main {
  height: 50vh;
  height: calc(var(--vh, 1vh) * 50);
  background-color: $white;
  .row {
    flex-direction: column;
    justify-content: center;
    h2 {
      font-size: clamp(1.7rem, 3vw, 4.2rem);
      line-height: clamp(1.9rem, 4vw, 4.5rem);
      font-weight: 600;
      z-index: 10;
      color: $white;
      mix-blend-mode: difference;
      @include media("<=phone") {
        margin-top: 72px;
      }
      .line {
        margin-bottom: 8px;
        height: clamp(32px, 3vw, 70px);
        position: relative;
        overflow: hidden;
        span {
          position: absolute;
        }
      }
    }
    .btn-row {
      width: clamp(256px, 20vw, 300px);
      position: relative;
      z-index: 2;
      a {
        font-size: clamp(1.1rem, 2vw, 2rem);
        color: $black;
        text-decoration: none;
        display: flex;
        align-items: center;
        font-weight: 600;
        svg {
          margin-left: 16px;
          border: 2px solid $black;
          padding: 12px;
          height: 24px;
          width: 24px;
          border-radius: 100px;
          transition: 0.4s ease-in-out;
          @include media("<=tablet") {
            padding: 6px;
            width: 20px;
            height: 20px;
          }
          @include media("<=phone") {
            padding: 4px;
            width: 16px;
            height: 16px;
          }
        }
        &:hover {
          svg {
            background: $black;
            color: $white;
          }
        }
      }
    }
  }
}
